import React, { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import './style.scss';

import { UserContext } from '../../App';
import { User } from '../../core/models';
import ActiveAlertsWidget from '../ActiveAlertsWidget/ActiveAlertsWidget';
import ActiveAlertsIcon from '../icons/ActiveAlertIcon/ActiveAlertsIcon';
import { ShippingButton } from '../ShippingButton/ShippingButton';
import MainSliderWelcomeSlide from './MainSliderWelcomeSlide/MainSliderWelcomeSlide';

interface MainSliderProps {
  handleLogout: () => void;
  isPlaying: boolean;
  isRequestAccessEnabled: boolean;
  profileLoading: boolean;
  isCCTFReceiptDisplayed: boolean;
}

/** Slick slider config. */
const sliderConfig = {
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
};

/**
 * Root component for page mail slider.
 */
const MainSlider: React.FC<MainSliderProps> = props => {
  const { handleLogout, isPlaying, isRequestAccessEnabled, profileLoading } = props;
  const user: User | null = useContext(UserContext);
  const sliderRef = useRef<Slider>(null);
  const [alertOpened, setAlertOpened] = useState<boolean>(false);

  const isAuthorized = user != null;
  const hasShippingButton = isAuthorized && props.isCCTFReceiptDisplayed;

  const deployActiveAlert = (): void => {
    setAlertOpened(true);
  };

  const onWidgetClose = (): void => {
    setAlertOpened(false);
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const renderActiveAlertWidget = user !== null && (
    <>
      <ActiveAlertsWidget opened={alertOpened} handleClose={onWidgetClose} />
      <ActiveAlertsIcon className="icon active-alerts-icon" onClick={deployActiveAlert} />
    </>
  );

  useEffect(() => {
    const sliderNode = sliderRef.current;
    sliderNode && (isPlaying ? sliderNode.slickPlay() : sliderNode.slickPause());
  }, [isPlaying]);

  return (
    <div className="block-slider">
      {renderActiveAlertWidget}
      <Slider ref={sliderRef} className="slick-dotted" {...sliderConfig}>
        <MainSliderWelcomeSlide
          profileLoading={profileLoading}
          user={user}
          isRequestAccessEnabled={isRequestAccessEnabled}
          handleLogout={handleLogout}
        />
        <MainSliderWelcomeSlide
          profileLoading={profileLoading}
          user={user}
          isRequestAccessEnabled={isRequestAccessEnabled}
          handleLogout={handleLogout}
        />
      </Slider>
      {hasShippingButton && (
        <div className="shipping-button">
          <ShippingButton />
        </div>
      )}
    </div>
  );
};

export default MainSlider;
